import config from "./env";
import { authHeader, headerForMulipart } from "../config/auth-header";
import { handleResponse } from "./responseHandler";

export const productService = {
  getAllProduct,
  createNewProduct,
};

function getAllProduct(offset, size) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/product/getAll?size=${size}&offset=${offset}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createNewProduct(param) {
  const formData = new FormData();
  let list = JSON.stringify(param.pVariant);
  formData.append("pName", param.pName);
  formData.append("pType", param.pType);
  formData.append("pCategory", param.pCategory);
  formData.append("pVariant", list);
  formData.append("productImage", param.productImage);

  const requestOptions = {
    method: "POST",
    headers: headerForMulipart(),
    body: formData,
  };
  console.log(param);

  return fetch(`${config.apiUrl}/product/createProduct`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
