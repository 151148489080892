import {UserConstants} from "./types";
import {userService} from "../services";

export const userActions = {
  login,
  logout,
  createAdmin,
  getAll,
  delete: _delete,
  clearError,
};

function login(user) {
  return (dispatch) => {
    dispatch(request({username: user.username}));

    userService.login(user.username, user.password).then(
      (userData) => {
        dispatch(success(userData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return {type: UserConstants.LOGIN_REQUEST, user};
  }
  function success(user) {
    return {type: UserConstants.LOGIN_SUCCESS, user};
  }
  function failure(error) {
    return {type: UserConstants.LOGIN_FAILURE, error};
  }
}

function logout() {
  userService.logout();
  return {type: UserConstants.LOGOUT};
}

function createAdmin(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.createAdmin(user).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return {type: UserConstants.REGISTER_REQUEST, user};
  }
  function success(user) {
    return {type: UserConstants.REGISTER_SUCCESS, user};
  }
  function failure(error) {
    return {type: UserConstants.REGISTER_FAILURE, error};
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {type: UserConstants.GETALL_REQUEST};
  }
  function success(users) {
    return {type: UserConstants.GETALL_SUCCESS, users};
  }
  function failure(error) {
    return {type: UserConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return {type: UserConstants.DELETE_REQUEST, id};
  }
  function success(id) {
    return {type: UserConstants.DELETE_SUCCESS, id};
  }
  function failure(id, error) {
    return {type: UserConstants.DELETE_FAILURE, id, error};
  }
}

function clearError() {
  return (dispatch) => {
    dispatch({type: UserConstants.CLEAR_ERROR});
  };
}
