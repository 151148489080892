import config from './env';
import {authHeader} from '../config/auth-header';
import {handleResponse} from './responseHandler';

export const farmersService = {
  getAllFarmers,
};

function getAllFarmers (offset) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users/allFarmers`, requestOptions)
    .then (handleResponse)
    .then (response => {
      return response;
    });
}
